import { useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';

import { CursorContext } from './../cursor/context/cursorContext';
import { AppContext } from './../layouts/AppProvider/AppProvider';

import './styles.scss';

const Fade = require("react-reveal/Fade");

export type NavTypes = {
  toggle: (variable: boolean) => void;
  toggled: boolean;
}

export const Nav = ({ toggled, toggle }: NavTypes) => {
  const { setType } = useContext(CursorContext);
  const { updateFirstLoad } = useContext(AppContext);

  const toggleOnClick = useCallback(
    (event: any) => {
      toggle(event.target.value);
      updateFirstLoad(true);
    },
    [toggle, updateFirstLoad]
  );

  return (
    <nav className={toggled ? 'nav active' : 'nav'}>
      <Fade bottom opposite cascade when={toggled} timeout={1500} duration={1500}>
        <ul>
          <Link
            className="btn btn_bold btn_nav"
            to="/"
            onClick={toggleOnClick}
            onMouseEnter={() => setType('link_nav')}
            onMouseLeave={() => setType('default')}
          >
            <li>meet clark</li>
          </Link>
          <Link
            className="btn btn_bold btn_nav"
            to="/skill-set"
            onClick={toggleOnClick}
            onMouseEnter={() => setType('link_nav')}
            onMouseLeave={() => setType('default')}
          >
            <li>time to show off</li>
          </Link>
          <Link
            className="btn btn_bold btn_nav"
            target="_blank"
            to="/CV-2023.pdf"
            onClick={toggleOnClick}
            onMouseEnter={() => setType('link_nav')}
            onMouseLeave={() => setType('default')}
          >
            <li>résumé</li>
          </Link>
          <Link
            className="btn btn_bold btn_nav"
            to="/contact"
            onClick={toggleOnClick}
            onMouseEnter={() => setType('link_nav')}
            onMouseLeave={() => setType('default')}
          >
            <li>get in touch</li>
          </Link>
        </ul>
      </Fade>
    </nav>
  );
};
